import request, { ResponseError } from '@capturi/request'
import {
  InfiniteData,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query'

import {
  KeyTopicConversationClusterRequest,
  KeyTopicConversationClusterResponse,
} from './types'

type Props = {
  sessionUid: string | undefined
  keyTopicClusterId: string | undefined
  body: KeyTopicConversationClusterRequest
  keySubTopicClusterId?: string
  sleepBeforeResolving?: boolean
}

//Kristian wishes to force a 1 second "sleep" time on the APIs promise before resolving
const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

export const useKeyTopicConversations = ({
  sessionUid,
  keyTopicClusterId,
  body,
  keySubTopicClusterId,
  sleepBeforeResolving = false,
}: Props): UseInfiniteQueryResult<
  InfiniteData<KeyTopicConversationClusterResponse>,
  ResponseError
> =>
  useInfiniteQuery<KeyTopicConversationClusterResponse, ResponseError>({
    queryKey: [
      'keyTopics',
      'session',
      sessionUid,
      'conversations',
      {
        clusterId: keyTopicClusterId,
        subClusterId: keySubTopicClusterId,
        keyTopics: body.keyTopics,
        keySubTopicClusterId: keySubTopicClusterId,
        onlyRepeatCalls: body.onlyRepeatCalls,
        pageSize: body.pageSize,
        phrases: body.phrases,
        ngrams: body.ngrams,
      },
      sleepBeforeResolving,
    ],
    queryFn: async ({ pageParam }) => {
      const sharedRequest = {
        pageNumber: pageParam || 0,
        pageSize: body.pageSize,
        phrases: body.phrases,
        ngrams: body.ngrams,
        onlyRepeatCalls: body.onlyRepeatCalls,
      }
      const url =
        keySubTopicClusterId !== undefined
          ? `keytopics/${sessionUid}/${keyTopicClusterId}/${keySubTopicClusterId}/list?api-version=3.3`
          : `keytopics/${sessionUid}/${keyTopicClusterId}/list?api-version=3.3`
      const [result] = await Promise.all([
        request.post<KeyTopicConversationClusterResponse>(url, {
          json:
            keySubTopicClusterId !== undefined
              ? {
                  ...sharedRequest,
                  keySubTopics: body.keyTopics,
                }
              : {
                  ...sharedRequest,
                  keyTopics: body.keyTopics,
                },
        }),
        sleepBeforeResolving ? sleep(1000) : Promise.resolve(),
      ])
      return result
    },

    getNextPageParam: (currentPage, allPages) => {
      if (currentPage === null) return undefined // Handle 204 No Content response
      if (currentPage.conversations.length < body.pageSize) return undefined
      return allPages.length
    },
    initialPageParam: null,
    staleTime: 1000 * 60 * 10, // 10 minutes
    enabled:
      !!body.keyTopics &&
      sessionUid !== undefined &&
      keyTopicClusterId !== undefined,
  })
