import {
  SearchParamsObject,
  toFilterSearchParams,
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import request, { ResponseError } from '@capturi/request'
import { useQuery } from '@tanstack/react-query'

import {
  RepeatCallFilterSettings,
  useRepeatCallFilterSettings,
} from '../pages/HomePage/Shared/useRepeatCallFilterSettings'
import type {
  KeyTopicsReadyResponse,
  KeyTopicsResponse,
} from '../pages/KeyTopics/hooks/types'

export type UseKeyTopicsResponse = KeyTopicsReadyResponse & {
  sessionUid: string
}

export type UseTopTimeConsumersKeyTopicsResponse =
  | UseKeyTopicsResponse
  | {
      status: 'Empty' // used when average duration is zero (no conversations matched filter)
    }

const AlternativeClusteringVersionQueryKey = 'alternativeClusteringVersion'

const getAlternativeClusteringVersion = () => {
  return new URLSearchParams(window.location.search).get(
    AlternativeClusteringVersionQueryKey,
  )
}

const getAverageDuration = async (json: SearchParamsObject) => {
  return await request
    .post<{ averageDuration: number }>('conversations/average-duration', {
      json,
      query: {
        'api-version': '3.3',
      },
    })
    .then((x) => x.averageDuration)
}

const checkStatus = async (
  sessionUid: string,
): Promise<UseKeyTopicsResponse> => {
  const data = await request.get<KeyTopicsResponse>(`keytopics/${sessionUid}`, {
    query: { 'api-version': '3.3' },
  })

  if (data.status === 'Failed') {
    throw new Error('Getting key topics data error')
  }

  if (data.status === 'Ready') {
    return {
      ...data,
      sessionUid,
    }
  }

  await new Promise((resolve) => setTimeout(resolve, 1000))
  return checkStatus(sessionUid)
}

const queryFn = async (
  filter: SearchParamsObject,
  repeatCallSettings?: RepeatCallFilterSettings,
) => {
  const alternativeClusteringVersion = getAlternativeClusteringVersion()
  const keyTopicSessionUid: string = await request
    .post<KeyTopicsResponse>('keytopics', {
      json: { filter, repeatCallSettings },
      query: {
        'api-version': '3.3',
        ...(alternativeClusteringVersion && {
          alternativeClusteringVersion,
        }),
      },
    })
    .then((x) => x.uid)

  return await checkStatus(keyTopicSessionUid)
}

export const useKeyTopics = <T>({
  select,
  includeRepeatCallSettings = true,
}: {
  select?: (data: UseKeyTopicsResponse) => T
  includeRepeatCallSettings?: boolean
} = {}) => {
  const segmentState = useFirstPhoneSegmentState()
  const { periodDef } = useFilterPeriodContext()
  const repeatCallSettings = useRepeatCallFilterSettings()
  const filter = {
    ...toFilterSearchParams({ ...segmentState?.values }, periodDef),
    limit: 20_000,
  }

  return useQuery<UseKeyTopicsResponse, ResponseError, T>({
    queryKey: [
      'keyTopics',
      'session',
      filter,
      { includeRepeatCallSettings, repeatCallSettings },
    ],
    queryFn: async () => {
      return await queryFn(
        filter,
        includeRepeatCallSettings ? repeatCallSettings : undefined,
      )
    },
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    select,
  })
}

export const useLongConversationKeyTopics = <T>({
  select,
}: {
  select?: (data: UseTopTimeConsumersKeyTopicsResponse) => T
} = {}) => {
  const segmentState = useFirstPhoneSegmentState()
  const { periodDef } = useFilterPeriodContext()
  const filter = toFilterSearchParams({ ...segmentState?.values }, periodDef)

  return useQuery<UseTopTimeConsumersKeyTopicsResponse, ResponseError, T>({
    queryKey: ['keyTopics', 'longSession', filter] as const,
    queryFn: async () => {
      const averageDuration = await getAverageDuration(filter)
      if (averageDuration === 0) {
        return { status: 'Empty' }
      }

      const longFilter = {
        ...filter,
        duration: { min: averageDuration * 2 },
        limit: 20000,
      }
      return await queryFn(longFilter)
    },
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    select,
  })
}
