import { useCurrentUser } from '@capturi/core'
import {
  FilterPeriodSelectContainer,
  SingleSegmentContainer,
  useFilterDefinitions,
} from '@capturi/filters'
import { usePageTitle } from '@capturi/react-utils'
import { PageHeading } from '@capturi/ui-components'
import { Button } from '@capturi/ui-components'
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { MdDownload } from 'react-icons/md'
import {
  UseTopTimeConsumersKeyTopicsResponse,
  useKeyTopics,
  useLongConversationKeyTopics,
} from '../../hooks/useKeyTopics'
import ConversationsSentimentWidget from './ConversationsSentimentWidget/ConversationsSentimentWidget'
import SessionCreationFailed from './FailedStates/SessionCreationFailed'
import { KeyTopicsWidget } from './KeyTopicsWidget'
import { LongConversationsExampleWidget } from './LongConversationsExampleWidget/LongConversationsExampleWidget'
import { LongConversationsWidget } from './LongConversationsWidget'
import { RepeatCallsWidget } from './RepeatCallsWidget'
import { ScoreWidget } from './ScoreWidget/ScoreWidget'
import { TopChangesWidget } from './TopChangesWidget'

const selectSessionUidProps = {
  select: (data: UseTopTimeConsumersKeyTopicsResponse) => {
    if (data.status === 'Empty') {
      return { status: 'empty' }
    }
    return {
      uid: data.uid,
    }
  },
}

const HomePage: React.FC = () => {
  usePageTitle(t`Home`)

  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)

  const {
    data,
    error: sharedKeyTopicsSessionError,
    refetch: startNewSharedSession,
  } = useKeyTopics(selectSessionUidProps)
  const {
    data: lcData,
    error: longConversationsKeyTopicsSessionError,
    refetch: startNewLongConversationsSession,
  } = useLongConversationKeyTopics(selectSessionUidProps)
  const user = useCurrentUser({ suspense: false })

  return (
    <Flex flexDir="column">
      <Box mb={7} flex="0 1 auto" overflowY="auto">
        <Flex align="center" justify="space-between" mb={8}>
          <Box>
            <PageHeading>
              <Trans>Greetings, {user.name.split(' ')[0]}</Trans>
            </PageHeading>
          </Box>
          <HStack>
            <FilterPeriodSelectContainer />
          </HStack>
        </Flex>
        <Flex flexDir="row" justifyContent="space-between">
          <SingleSegmentContainer filterDefinitions={filterDefinitions} />
          <HStack>
            <Tooltip
              label={t`Get this sent to your inbox recurringly`}
              hasArrow
              placement="top"
            >
              <Button
                size="xs"
                color="primary.500"
                borderRadius="999"
                borderColor="primary.500"
                variant="outline"
                _hover={{ bg: 'primary.50' }}
                _active={{ bg: 'primary.100' }}
                data-stonly="Subscribe to frontpage"
                aria-label={t`Get this sent to your inbox recurringly`}
              >
                <Trans>Subscribe</Trans>
              </Button>
            </Tooltip>
            <Tooltip label={t`Download report`} hasArrow placement="top">
              <IconButton
                aria-label={t`Download report`}
                fontSize="8px"
                icon={<MdDownload />}
                size="xs"
                variant="solid"
                color="gray.800"
                data-stonly="Download as a report"
              />
            </Tooltip>
          </HStack>
        </Flex>
      </Box>
      <VStack alignItems="stretch" flex="1 1 auto" mb="30px" gap={16}>
        {sharedKeyTopicsSessionError ? (
          <SessionCreationFailed
            error={sharedKeyTopicsSessionError}
            startNewSession={startNewSharedSession}
          />
        ) : (
          <TopChangesWidget
            keyTopicsSessionUid={data?.uid}
            startNewSession={startNewSharedSession}
          />
        )}
        <ConversationsSentimentWidget />
        {sharedKeyTopicsSessionError ? (
          <SessionCreationFailed
            error={sharedKeyTopicsSessionError}
            startNewSession={startNewSharedSession}
          />
        ) : (
          <KeyTopicsWidget
            keyTopicsSessionUid={data?.uid}
            startNewSession={startNewSharedSession}
          />
        )}
        <ScoreWidget />
        {longConversationsKeyTopicsSessionError ? (
          <SessionCreationFailed
            error={longConversationsKeyTopicsSessionError}
            startNewSession={startNewLongConversationsSession}
          />
        ) : (
          <LongConversationsWidget
            keyTopicsSessionUid={lcData?.uid}
            startNewSession={startNewLongConversationsSession}
          />
        )}
        <LongConversationsExampleWidget />
        {sharedKeyTopicsSessionError ? (
          <SessionCreationFailed
            error={sharedKeyTopicsSessionError}
            startNewSession={startNewSharedSession}
          />
        ) : (
          <RepeatCallsWidget
            keyTopicsSessionUid={data?.uid}
            startNewSession={startNewSharedSession}
          />
        )}
      </VStack>
    </Flex>
  )
}

export default HomePage
