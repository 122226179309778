import analytics from '@capturi/analytics'
import { useAiQuestionAnswers } from '@capturi/api-ask-ai'
import { Summary } from '@capturi/api-summaries'
import Icon_AI from '@capturi/assets/images/AI_small.svg'
import { formatTime } from '@capturi/audio'
import { useTeams } from '@capturi/core'
import { useUsers } from '@capturi/stores'
import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Skeleton,
  SkeletonCircle,
  Tag,
  Text,
  VStack,
  useTheme,
  useToast,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import React, { useEffect, useRef, useState } from 'react'
import {
  MdKeyboardArrowUp,
  MdOutlineThumbDown,
  MdOutlineThumbUp,
} from 'react-icons/md'

import { useConversationDetailsDrawer } from '../../../components/ConversationDetailsDrawer'

type Props = {
  summaryData: Summary
  question?: string
  answer?: string
}

const dateFormat: Intl.DateTimeFormatOptions = {
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

const MAX_SUMMARY_HEIGHT = 110

const AskAiAnswerList: React.FC<Props> = ({ summaryData, question }) => {
  const { getTeamByUid } = useTeams()
  const { getUserByUid } = useUsers()

  const user = getUserByUid(summaryData.userUid)
  const team = getTeamByUid(summaryData?.teamUid)

  const answerRef = useRef<HTMLDivElement>(null)

  const [show, setShow] = React.useState(false)
  const handleToggleShow = (): void => setShow((s) => !s)
  const ref = useRef<HTMLParagraphElement>(null)
  const [height, setHeight] = useState(MAX_SUMMARY_HEIGHT)
  const forceShow = MAX_SUMMARY_HEIGHT > (ref.current?.offsetHeight || 0)

  const {
    data: answers,
    isLoading,
    error: answerError,
  } = useAiQuestionAnswers({
    conversationUids: [summaryData.uid],
    question: question,
  })

  const emptyAnswer = answers?.some((a) => a.answer === null)

  const hasLimit = answerError?.statusCode === 429

  const openConversationDetailsDrawer = useConversationDetailsDrawer()
  const toast = useToast()
  const theme = useTheme()
  const [reviewed, setReviewed] = useState(false)

  // biome-ignore lint/correctness/useExhaustiveDependencies: legacy
  useEffect(() => {
    if (answers) {
      setHeight(
        ref.current?.offsetHeight &&
          answerRef.current?.offsetHeight &&
          !Number.isNaN(answerRef.current?.offsetHeight)
          ? ref.current?.offsetHeight + answerRef.current?.offsetHeight
          : MAX_SUMMARY_HEIGHT,
      )
      analytics.event('askAi_answer&question', {
        answerData: {
          answer: answers?.[0].answer,
          question: question,
        },
      })
      if (hasLimit)
        analytics.event('askAi_limitReached', {
          answerData: {
            answer: answers?.[0].answer,
            question: question,
            status: answerError?.statusCode,
          },
        })
    }
  }, [answerError?.statusCode, answers, hasLimit, isLoading, question])

  return (
    <Box flexDirection="column" mb={4}>
      <Box
        borderColor="transparent"
        pos="relative"
        bg="white"
        borderRadius="3px"
        css={css`&:before {
            	content: '';
            	position: absolute;
            	top: 0;
            	right: 0;
            	bottom: 0;
         		left: 0;
            	z-index: -1;
            	margin: -1px;
            	border-radius: 4px;
            	opacity: 0.5;
            	background: ${theme.colors.aiGradient};
          		}`}
      >
        <Box borderRadius={4} position="relative" overflow="hidden" w="100%">
          <Box
            px={4}
            py={2}
            bg="gray.50"
            borderBottom="1px solid"
            borderColor="gray.200"
            cursor="pointer"
            onClick={() => {
              openConversationDetailsDrawer({
                uid: summaryData.uid,
                showOverlay: true,
              })
            }}
            _hover={{ bg: 'gray.100' }}
          >
            <HStack>
              <Flex w="100%" justifyContent="space-between">
                <VStack alignItems="start" gap={0}>
                  <Text fontSize="md" fontWeight="medium">
                    {summaryData.subject}
                  </Text>
                  <Text fontSize="md" color="gray.600">
                    {formatTime(summaryData.duration)} <Trans>Minutes</Trans>
                  </Text>
                </VStack>
                <VStack gap={1} alignItems="end">
                  <Flex gap={2}>
                    {team?.name && <Tag>{team?.name}</Tag>}{' '}
                    <Text fontWeight="medium" fontSize="sm">
                      {user.name}
                    </Text>
                  </Flex>
                  <Text fontSize="xs" color="gray.600">
                    {i18n.date(summaryData.dateTime, dateFormat)}
                  </Text>
                </VStack>
              </Flex>
            </HStack>
          </Box>
          <HStack
            pt={2}
            w="100%"
            justifyContent="space-between"
            alignItems="start"
            cursor={forceShow ? undefined : 'pointer'}
            onClick={handleToggleShow}
            _hover={{
              background: forceShow ? undefined : 'gray.200',
            }}
          >
            <Box gap={2} textAlign="start">
              <Box
                transition="height 200ms"
                height={forceShow || show ? height : MAX_SUMMARY_HEIGHT}
                mb="4"
              >
                <>
                  {emptyAnswer && (
                    <Text ml={8} whiteSpace="pre-wrap">
                      <Trans>
                        Sorry, I can not answer your question on this
                        conversation
                      </Trans>
                    </Text>
                  )}
                  {!answers && isLoading ? (
                    <Flex flexDir="column" gap={1} pl={2}>
                      <SkeletonCircle pos="absolute" h={6} w={6} />
                      <Skeleton h={5} ml={8} w="95%" />
                      <Skeleton h={5} ml={8} w="95%" />
                      <Skeleton h={5} ml={8} w="95%" />
                    </Flex>
                  ) : (
                    <Box pl={2} ref={answerRef}>
                      <Icon
                        pos="absolute"
                        as={Icon_AI}
                        boxSize="4"
                        ml="2"
                        mt="0.5"
                      />
                      {hasLimit ? (
                        <Text ml={8} whiteSpace="pre-wrap" color="danger">
                          <Trans>
                            Unfortunately, you've reached the request limit.
                            Please wait 1 minute and try again.
                          </Trans>
                        </Text>
                      ) : (
                        <Text ml={8} whiteSpace="pre-wrap">
                          {answers?.map((a) => a.answer)}
                        </Text>
                      )}
                    </Box>
                  )}
                </>
                <Divider w="100%" m={2} mb={4} />
                <Box overflow="hidden">
                  <Box ref={ref} ml={8}>
                    {answers && (
                      <Text fontWeight="medium" fontSize="sm">
                        <Trans>Summary</Trans>
                      </Text>
                    )}
                    <Text color="gray.600" whiteSpace="pre-wrap" pb={2}>
                      {summaryData.summary}
                    </Text>
                  </Box>
                </Box>
              </Box>
              {!forceShow && (
                <Box
                  transition="height 300ms"
                  id="gradientBox"
                  pos={show ? 'static' : 'absolute'}
                  bottom="0px"
                  w="100%"
                  h={show ? '12px' : '50px'}
                  background={
                    show
                      ? 'none'
                      : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 100%)'
                  }
                >
                  <Icon
                    transform={show ? 'scale(1, 1)' : 'scale(1, -1)'}
                    pos="absolute"
                    left="50%"
                    ml="-20px"
                    bottom="4px"
                    as={MdKeyboardArrowUp}
                    fontSize="20px"
                  />
                </Box>
              )}
            </Box>
            {!reviewed && (
              <HStack gap="1" pr={2}>
                <IconButton
                  aria-label="Thumbs up"
                  icon={<MdOutlineThumbUp />}
                  size="xs"
                  variant="ghost"
                  color="gray.500"
                  onClick={(e) => {
                    e.stopPropagation()
                    analytics.event('summary_ThumbsUp', {
                      summary: summaryData,
                      summaryUid: summaryData.uid,
                    })
                    setReviewed(true)
                    toast({
                      title: t`Thank you for the feedback`,
                      description: t`You are helping us to improve future summaries`,
                      status: 'success',
                      duration: 4000,
                      isClosable: true,
                    })
                  }}
                />
                <IconButton
                  aria-label="Thumbs down"
                  fontSize="8px"
                  icon={<MdOutlineThumbDown />}
                  size="xs"
                  variant="ghost"
                  color="gray.500"
                  onClick={(e) => {
                    e.stopPropagation()
                    analytics.event('summary_ThumbsDown', {
                      summary: summaryData,
                      conversationUid: summaryData.uid,
                    })
                    setReviewed(true)
                    toast({
                      title: t`Thank you for the feedback`,
                      description: t`You are helping us to improve future summaries`,
                      status: 'success',
                      duration: 4000,
                      isClosable: true,
                    })
                  }}
                />
              </HStack>
            )}
          </HStack>
        </Box>
      </Box>
    </Box>
  )
}

export default AskAiAnswerList
