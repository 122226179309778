import request from '@capturi/request'
import { useQueryClient } from '@tanstack/react-query'

import { Role, Team, UserPermissions, UserResponse } from '../types'

export type UiRole = Role | 'owner'

type PatchUserBody = {
  name: string
  email: string
  title: string
  permissions: UserPermissions
  teamUid: string | null
  role: Role
}

export type PatchUser = (
  userUid: string,
  user: Partial<PatchUserBody>,
) => Promise<void>

const baseUrl = 'authentication/users'
export const queryKey = [`${baseUrl}?includeDeleted=false`]

export const defaultUserPermissions: UserPermissions = {
  organization: false,
  editDashboard: false,
  editTracker: false,
  editSegment: false,
  editScore: false,
  editLibrary: false,
  playback: false,
  download: false,
  editEverything: false,
  editMasterTracker: false,
  viewReports: false,
  text: false,
  qualityAssurance: false,
  hideUserInfo: false,
}

export const defaultPermissionsPerRole: {
  [key in UiRole]: Partial<UserPermissions>
} = {
  owner: {
    editEverything: false,
    organization: true,
    editDashboard: true,
    editTracker: true,
    editSegment: true,
    editScore: true,
    editLibrary: true,
    playback: true,
    download: true,
    qualityAssurance: true,
    viewReports: true,
  },
  administrator: {
    editDashboard: true,
    editTracker: true,
    editSegment: true,
    editScore: true,
    editLibrary: true,
    playback: true,
    download: true,
    qualityAssurance: true,
    viewReports: true,
  },
  teamlead: {
    editDashboard: true,
    editTracker: true,
    editScore: true,
    editSegment: true,
    editLibrary: true,
    playback: true,
    qualityAssurance: true,
  },
  user: {
    playback: true,
    editLibrary: true,
    editSegment: false,
    editDashboard: false,
    editTracker: false,
    editScore: false,
  },
}

const mutateUserCache = (
  originalUsers: { users: UserResponse[] } | undefined,
  newUserUid: string,
  newUser: Partial<UserResponse>,
): { users: UserResponse[] } | undefined => {
  if (originalUsers === undefined) return undefined
  const newUsers = originalUsers.users.map((o) =>
    o.id === newUserUid ? { ...o, ...newUser } : o,
  )
  return { users: newUsers }
}

/** 
On the frontend we have an extra role "owner", that doesn't exists on the backend
so before we patch the user on the backend we have to convert the role to a valid server role.
A owner role is just a administrator with the organization permission
*/
export const toServerRole = (
  role: UiRole,
): { role: Role; permissions: Partial<UserPermissions> } => {
  if (role === 'owner')
    return {
      role: 'administrator',
      permissions: defaultPermissionsPerRole.owner,
    }
  if (role === 'administrator') {
    return {
      role: 'administrator',
      permissions: {
        ...defaultPermissionsPerRole.administrator,
        download: false,
      },
    }
  }

  return { role: role, permissions: defaultPermissionsPerRole[role] }
}

/**
On the frontend we have an extra role "owner", that doesn't exists on the backend.
A owner role is just a administrator with the organization permission
*/
export const toUiRole = (
  role: Role,
  permissions: Partial<UserPermissions>,
): UiRole => {
  if (role === 'administrator' && permissions.organization) {
    return 'owner'
  }
  return role
}

export const useUserActions = (): {
  patchUser: (userUid: string, user: Partial<PatchUserBody>) => Promise<void>
  changeRole: (userUid: string, newRole: UiRole) => Promise<void>
  changeTeamLeadTeams: (userUid: string, teams: Team[]) => Promise<void>
  unassignUser: (userUid: string) => Promise<void>
  inviteUser: (userUid: string, email: string) => Promise<void>
  createUser: (User: {
    name: string
    email: string
    sendInvite: boolean
    role: Role
    permissions: Partial<UserPermissions>
    language: string
  }) => Promise<void>
} => {
  const queryClient = useQueryClient()

  const patchUser = async (
    userUid: string,
    user: Partial<PatchUserBody>,
  ): Promise<void> => {
    const previousUsers = queryClient.getQueryData<{ users: UserResponse[] }>(
      queryKey,
    )
    try {
      queryClient.setQueryData<{ users: UserResponse[] } | undefined>(
        queryKey,
        (o) => mutateUserCache(o, userUid, user),
      )
      await request.patch(`${baseUrl}/${userUid}`, {
        json: user,
      })
    } catch (error) {
      queryClient.setQueryData<{ users: UserResponse[] } | undefined>(
        queryKey,
        previousUsers,
      )
      throw error
    } finally {
      queryClient.invalidateQueries({
        queryKey,
      })
    }
  }

  const changeRole = async (
    userUid: string,
    newRole: UiRole,
  ): Promise<void> => {
    const previousUsers = queryClient.getQueryData<{ users: UserResponse[] }>(
      queryKey,
    )
    try {
      const { permissions, role } = toServerRole(newRole)
      queryClient.setQueryData<{ users: UserResponse[] } | undefined>(
        queryKey,
        (o) => mutateUserCache(o, userUid, { role: role, permissions }),
      )

      await request.patch(`${baseUrl}/${userUid}`, {
        json: { role: role, permissions },
      })
    } catch (error) {
      queryClient.setQueryData<{ users: UserResponse[] } | undefined>(
        queryKey,
        previousUsers,
      )
      throw error
    } finally {
      queryClient.invalidateQueries({
        queryKey,
      })
    }
  }

  const changeTeamLeadTeams = async (
    userUid: string,
    teams: Team[],
  ): Promise<void> => {
    const previousUsers = queryClient.getQueryData<{ users: UserResponse[] }>(
      queryKey,
    )
    try {
      queryClient.setQueryData<{ users: UserResponse[] } | undefined>(
        queryKey,
        (o) => mutateUserCache(o, userUid, { teamLeadTeams: teams }),
      )
      await request.patch(`${baseUrl}/${userUid}`, {
        json: { teamLeadTeamUids: teams.map((t) => t.uid) },
      })
    } catch (error) {
      queryClient.setQueryData<{ users: UserResponse[] } | undefined>(
        queryKey,
        previousUsers,
      )
      throw error
    } finally {
      queryClient.invalidateQueries({
        queryKey,
      })
    }
  }

  const unassignUser = async (userUid: string): Promise<void> => {
    const previousUsers = queryClient.getQueryData<{ users: UserResponse[] }>(
      queryKey,
    )
    try {
      queryClient.setQueryData<{ users: UserResponse[] } | undefined>(
        queryKey,
        (o) => mutateUserCache(o, userUid, { inviteStatus: 'unknown' }),
      )

      await request.post(`authentication/organization/user/${userUid}/unassign`)
    } catch (error) {
      queryClient.setQueryData<{ users: UserResponse[] } | undefined>(
        queryKey,
        previousUsers,
      )
      throw error
    } finally {
      queryClient.invalidateQueries({
        queryKey,
      })
    }
  }
  const inviteUser = async (userUid: string, email: string): Promise<void> => {
    const previousUsers = queryClient.getQueryData<{ users: UserResponse[] }>(
      queryKey,
    )
    try {
      queryClient.setQueryData<{ users: UserResponse[] } | undefined>(
        queryKey,
        queryClient.setQueryData<{ users: UserResponse[] } | undefined>(
          queryKey,
          (o) => mutateUserCache(o, userUid, { inviteStatus: 'sent' }),
        ),
      )

      await request.post(`authentication/organization/invite/${userUid}`, {
        json: { email: email },
      })
    } catch (error) {
      queryClient.setQueryData<{ users: UserResponse[] } | undefined>(
        queryKey,
        previousUsers,
      )
      throw error
    } finally {
      queryClient.invalidateQueries({
        queryKey,
      })
    }
  }

  const createUser = async ({
    email,
    name,
    permissions,
    role,
    sendInvite,
    language,
  }: {
    name: string
    email: string
    sendInvite: boolean
    role: Role
    permissions: Partial<UserPermissions>
    language: string
  }): Promise<void> => {
    const previousUsers = queryClient.getQueryData<{ users: UserResponse[] }>(
      queryKey,
    )
    try {
      const resp = await request.post<{ uid: string; organizationUid: string }>(
        'authentication/users',
        {
          json: {
            email,
            name,
            sendInvite,
            role,
            permissions,
            language,
          },
        },
      )
      queryClient.setQueryData<{ users: UserResponse[] } | undefined>(
        queryKey,
        (old) =>
          old && {
            users: [
              {
                name: name,
                email: email,
                role: role,
                id: resp.uid,
                inviteStatus: sendInvite ? 'sent' : 'unknown',
                profileImage: null,
                team: null,
                title: '',
                isDeleted: false,
                organizationId: resp.organizationUid,
                teamLeadTeams: null,
                mostRecentConversation: null,
                lastTokenRefresh: null,
                permissions,
              },
              ...old.users,
            ],
          },
      )
    } catch (error) {
      queryClient.setQueryData<{ users: UserResponse[] } | undefined>(
        queryKey,
        previousUsers,
      )
      throw error
    } finally {
      queryClient.invalidateQueries({
        queryKey,
      })
    }
  }

  return {
    patchUser,
    changeRole,
    changeTeamLeadTeams,
    unassignUser,
    inviteUser,
    createUser,
  }
}
