import analytics from '@capturi/analytics'
import { Card, CardContent, PlaySnippetsButton } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Skeleton,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useCallback, useMemo } from 'react'
import { MdTrendingUp } from 'react-icons/md'

import { KeyTopicsDrawer } from '../../../components/KeyTopics/Drawer'
import { KeyTopicsWordCloud } from '../../../components/KeyTopics/KeyTopicsWordCloud'
import { WordCloudItem } from '../../../components/WordCloud'
import EmptyWidget from '../FailedStates/EmptyWidget'
import { AnimateChangeInHeight } from '../Shared/AnimateHeight'
import SharedDigest from '../Shared/SharedDigest'
import { ExpectedVsActualBarChart } from './components/ExpectedVsActualBarChart'
import { KeyTopicsList } from './components/KeyTopicsList'
import {
  TopChangesWidgetReadySelector,
  TopChangesWidgetSelectorTopics,
} from './selector'

export const TopChangesWidgetCardSkeleton: React.FC = () => {
  return (
    <Card mt={2} h="665px">
      <CardContent h="100%">
        <Flex alignItems="stretch" gap={4} w="100%" h="100%">
          <VStack alignItems="flex-start" gap={3}>
            <Skeleton height="22px" width="70%" mt={1} />
            <VStack width="16.5rem" height="100%" gap={2}>
              <Skeleton borderRadius={8} w="100%" h="60px" />
              <Skeleton borderRadius={8} w="100%" h="60px" />
              <Skeleton borderRadius={8} w="100%" h="60px" />
              <Skeleton borderRadius={8} w="100%" h="60px" />
              <Skeleton borderRadius={8} w="100%" h="60px" />
            </VStack>
          </VStack>
          <Divider orientation="vertical" />
          <VStack alignItems="flex-start" gap={3} flexGrow={1}>
            <VStack w="100%" alignItems="flex-start" gap={1}>
              <Skeleton height="20px" width="15%" />
              <Skeleton height="14px" width="40%" />
            </VStack>
            <Grid
              w="100%"
              h="665px"
              gridColumnGap={2}
              gridRowGap={4}
              templateRows="292px 1fr"
              templateColumns="1fr 228px"
            >
              <GridItem rowSpan={1} colSpan={1}>
                <VStack w="100%" h="100%" flexGrow="1" alignItems="flex-start">
                  <Skeleton height="81px" width="100%" />
                  <Skeleton height="100%" width="100%" />
                </VStack>
              </GridItem>
              <GridItem borderRadius={4} rowSpan={1} colSpan={1}>
                <Skeleton height="100%" width="100%" />
              </GridItem>
              <GridItem borderRadius={4} rowSpan={2} colSpan={2}>
                <Skeleton height="100%" width="100%" />
              </GridItem>
            </Grid>
          </VStack>
        </Flex>
      </CardContent>
    </Card>
  )
}

const NoTopChangesWidgetCard: React.FC = () => {
  return (
    <Card mt={2}>
      <CardContent h="100%">
        <HStack alignItems="stretch" gap={4} w="100%" h="100%">
          <VStack alignItems="stretch" width="100%">
            <HStack gap={1}>
              <Icon boxSize={5} as={MdTrendingUp} />
              <Text fontSize="lg" fontWeight="medium" lineHeight="5">
                <Trans>Increase</Trans>
              </Text>
            </HStack>
            <EmptyWidget
              heading={t`No topics with increases`}
              body={t`With the current selection there's hasn't been any increases in the amount of conversations for a given topic.`}
            />
          </VStack>
        </HStack>
      </CardContent>
    </Card>
  )
}

export const TopChangesWidgetCard: React.FC<{
  keyTopicsSessionUid: string | undefined
  data: TopChangesWidgetReadySelector
  wordCloud: { [key: string]: number } | undefined
  trendWordCloud: { [key: string]: number } | undefined
  notablePhrases: string[]
  selectedKeyTopicCluster: TopChangesWidgetSelectorTopics | undefined
  setSelectedKeyTopicClusterId: (id: string) => void
}> = ({
  keyTopicsSessionUid,
  data,
  wordCloud,
  trendWordCloud,
  notablePhrases,
  selectedKeyTopicCluster,
  setSelectedKeyTopicClusterId,
}) => {
  const [openKeyTopicsDrawer] = useModal(KeyTopicsDrawer)

  const { considerableChangesItems, normalItems } = useMemo(() => {
    const considerableChangesItems: TopChangesWidgetSelectorTopics[] = []
    const normalItems: TopChangesWidgetSelectorTopics[] = []
    data.keyTopicClusters.forEach((topic) => {
      if (topic.significant) {
        considerableChangesItems.push(topic)
      } else {
        normalItems.push(topic)
      }
    })
    return { considerableChangesItems, normalItems }
  }, [data])

  const handleOpenKeyTopicsDrawer = useCallback(
    (selectedWordCloudItem?: string) => {
      if (!(selectedKeyTopicCluster && keyTopicsSessionUid)) return
      openKeyTopicsDrawer({
        topicId: selectedKeyTopicCluster.id,
        sessionUid: keyTopicsSessionUid,
        referenceBenchmark: data.benchmark,
        clusterName: selectedKeyTopicCluster.label,
        topics: selectedKeyTopicCluster.keyTopics,
        selectedWordCloudItem,
        initialWordCloudType: 'Drivers',
      })
      analytics.event('top_changes_drawer_opened')
    },
    [
      data.benchmark,
      keyTopicsSessionUid,
      openKeyTopicsDrawer,
      selectedKeyTopicCluster,
    ],
  )

  const handleSelectWordCloudItem = useCallback(
    (item: WordCloudItem | undefined) => {
      handleOpenKeyTopicsDrawer(item?.name)
      analytics.event('top_changes_wordcloud_selected')
    },
    [handleOpenKeyTopicsDrawer],
  )

  if (data.keyTopicClusters.length === 0) {
    return <NoTopChangesWidgetCard />
  }

  return (
    <Card mt={2}>
      <CardContent h="100%">
        <HStack alignItems="stretch" gap={4} w="100%" h="100%">
          <VStack alignItems="stretch" gap={3}>
            <HStack gap={1}>
              <Icon boxSize={5} as={MdTrendingUp} />
              <Text fontSize="lg" fontWeight="medium" lineHeight="5">
                <Trans>Increase</Trans>
              </Text>
            </HStack>
            <KeyTopicsList
              considerableChangesItems={considerableChangesItems}
              normalItems={normalItems}
              selectedKeyTopicId={selectedKeyTopicCluster?.id}
              setSelectedKeyTopicId={setSelectedKeyTopicClusterId}
            />
          </VStack>
          <Box
            backgroundColor="gray.200"
            position="relative"
            w="1px"
            top="0"
            bottom="0"
          />
          <VStack w="100%" alignItems="stretch">
            <Flex gap="8" justifyContent="space-between" w="100%">
              <Flex flexDir="column" w="200px" flexGrow="1">
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  textColor="gray.800"
                  textTransform="uppercase"
                >
                  {selectedKeyTopicCluster?.label}
                </Text>
                <Text
                  fontSize="xs"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  textColor="gray.600"
                  w="100%"
                >
                  {selectedKeyTopicCluster?.keyTopics
                    ?.slice(
                      1,
                      5,
                    ) /* Skip first topic and stop at the fifth, as it's in label */
                    .map((topic) => topic.name)
                    .join(', ')}
                  {selectedKeyTopicCluster &&
                    selectedKeyTopicCluster?.keyTopics.length > 5 && (
                      <Trans>, etc.</Trans>
                    )}
                </Text>
              </Flex>
              <PlaySnippetsButton
                data-stonly="top-changes-discover-drawer-button"
                onClick={(e) => {
                  e.stopPropagation()
                  handleOpenKeyTopicsDrawer()
                }}
                label={t`Discover`}
              />
            </Flex>
            <AnimateChangeInHeight>
              <Grid
                w="100%"
                h="fit"
                gridColumnGap={2}
                gridRowGap={4}
                templateRows="auto 1fr"
                templateColumns="1fr 228px"
              >
                <GridItem rowSpan={1} colSpan={1}>
                  <Flex flexDirection="column" h="100%" gap={2}>
                    <Box
                      flexGrow={0}
                      borderColor="gray.200"
                      borderWidth="1px"
                      borderRadius={4}
                      py={3}
                      px={4}
                    >
                      <Text fontSize="sm" fontWeight="medium">
                        <Trans>Summary</Trans>
                      </Text>
                      {selectedKeyTopicCluster && (
                        <Text fontSize="md" textColor="gray.600">
                          {selectedKeyTopicCluster.amountVsExpectedDiff >= 0 ? (
                            <Trans>
                              {selectedKeyTopicCluster.amount} conversations are
                              about{' '}
                              <chakra.span fontWeight="medium">
                                {selectedKeyTopicCluster.label}
                              </chakra.span>{' '}
                              and related topics, which is{' '}
                              {selectedKeyTopicCluster.amountVsExpectedDiff}{' '}
                              more than expected.
                            </Trans>
                          ) : (
                            <Trans>
                              {selectedKeyTopicCluster.amount} conversations are
                              about{' '}
                              <chakra.span fontWeight="medium">
                                {selectedKeyTopicCluster.label}
                              </chakra.span>
                              , which is{' '}
                              {selectedKeyTopicCluster.amountVsExpectedDiff}{' '}
                              less than expected.
                            </Trans>
                          )}
                          {notablePhrases.length > 0 && (
                            <>
                              {' '}
                              <Trans>
                                Some of the trending phrases include{' '}
                                <chakra.span fontWeight="medium">
                                  {notablePhrases
                                    .map((phrase) => `"${phrase}"`)
                                    .join(', ')}
                                </chakra.span>
                                .
                              </Trans>
                            </>
                          )}
                        </Text>
                      )}
                    </Box>
                    <AnimateChangeInHeight>
                      <Box
                        flexGrow={1}
                        position="relative"
                        w="100%"
                        h="100%"
                        data-stonly="top-changes-reason-for-increase-in-conversation"
                      >
                        <SharedDigest
                          keyTopicsSessionUid={keyTopicsSessionUid}
                          keyTopicClusterId={selectedKeyTopicCluster?.id}
                          keyTopicClusterLabel={t`What seems to be causing the increase in conversations about ${selectedKeyTopicCluster?.label}?`}
                          widgetType="topChanges"
                        />
                      </Box>
                    </AnimateChangeInHeight>
                  </Flex>
                </GridItem>
                <GridItem rowSpan={1} colSpan={1}>
                  <Box
                    borderColor="gray.200"
                    borderWidth="1px"
                    borderRadius={4}
                    pt={3}
                    px={4}
                    pb={4}
                    w="100%"
                    h="100%"
                  >
                    <Flex
                      flexDir="column"
                      alignItems="flex-start"
                      gap={2}
                      w="100%"
                      h="100%"
                    >
                      <Text
                        fontSize="sm"
                        fontWeight="medium"
                        textColor="gray.600"
                      >
                        <Trans>Change</Trans>
                      </Text>
                      <Box flex="1 1 auto" w="100%" h="100%">
                        <ExpectedVsActualBarChart
                          actualAmount={selectedKeyTopicCluster?.amount ?? 0}
                          expectedAmount={
                            selectedKeyTopicCluster?.expected ?? 0
                          }
                          amountVsExpectedDiff={
                            selectedKeyTopicCluster?.amountVsExpectedDiff ?? 0
                          }
                          significant={
                            selectedKeyTopicCluster?.significant ?? false
                          }
                        />
                      </Box>
                    </Flex>
                  </Box>
                </GridItem>
                <GridItem rowSpan={2} colSpan={2}>
                  <Flex
                    borderColor="gray.200"
                    borderWidth="1px"
                    borderRadius={4}
                    direction="column"
                    pt={3}
                    w="100%"
                    minH="300px"
                    h="100%"
                    data-stonly="top-changes-word-cloud"
                  >
                    <Text
                      flexGrow={0}
                      fontSize="sm"
                      fontWeight="medium"
                      px={4}
                      textColor="gray.600"
                    >
                      <Trans>Phrases potentially driving the increase</Trans>
                    </Text>
                    {trendWordCloud && (
                      <KeyTopicsWordCloud
                        wordCloudType="Drivers"
                        wordCloud={wordCloud}
                        trendWordCloud={trendWordCloud}
                        onSelectItem={handleSelectWordCloudItem}
                      />
                    )}
                  </Flex>
                </GridItem>
              </Grid>
            </AnimateChangeInHeight>
          </VStack>
        </HStack>
      </CardContent>
    </Card>
  )
}
