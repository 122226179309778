import analytics from '@capturi/analytics'
import { useAiQuestions } from '@capturi/api-ask-ai'
import {
  Box,
  Button,
  Collapse,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import noop from 'lodash/noop'
import React, { useState } from 'react'
import {
  MdBookmarkAdd,
  MdBookmarks,
  MdCheckCircle,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdSearch,
  MdSend,
} from 'react-icons/md'

import { useQuestionsLocalStorage } from '../../../hooks/usePersonalQuestions'
import DynamicTextArea from './DynamicTextArea'
import PersonalQuestions from './PersonalQuestions'

type Props = {
  hasPostedState: boolean
  handleSubmit: (value: string) => void
}

const QuestionInput: React.FC<Props> = ({ hasPostedState, handleSubmit }) => {
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const { data: aiQuestions } = useAiQuestions()
  const {
    questions: personalQuestions,
    addQuestion,
    deleteQuestion,
  } = useQuestionsLocalStorage()
  const [inputValue, setInputValue] = useState('')
  const trimmedInputValue = inputValue.trim()
  const toast = useToast()

  const toggleCollapse = (): void => {
    setCollapsed(!collapsed)
  }

  return (
    <>
      <Box textAlign="center">
        <HStack pos="relative">
          {trimmedInputValue && (
            <Tooltip label={t`Save question`} hasArrow placement="top">
              <Box
                as="span"
                pos="absolute"
                position="absolute"
                cursor="pointer"
                zIndex="999"
                right="65px"
                top="50%"
                mt="-9px"
              >
                <Icon
                  as={MdBookmarkAdd}
                  color="gray.800"
                  boxSize="18px"
                  onClick={() => {
                    addQuestion(trimmedInputValue)
                    analytics.event('save_personal_question', {
                      question: trimmedInputValue,
                    })
                    toast({
                      title: t`Personal question saved`,
                      status: 'success',
                      icon: <Icon as={MdCheckCircle} boxSize={6} />,
                    })
                  }}
                />
              </Box>
            </Tooltip>
          )}
          <DynamicTextArea
            resize="none"
            minH="48px"
            maxH="calc( 80vh - 228px)"
            value={inputValue}
            onChange={(e) => setInputValue(e.currentTarget.value)}
            p={3}
            pr={7}
            overflowY="scroll"
            boxShadow="extended"
            placeholder={t`Did the agent resolve the enquiry?`}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && trimmedInputValue && !e.shiftKey) {
                e.stopPropagation()
                handleSubmit(trimmedInputValue)
                analytics.event('askAi_submitButton', {
                  question: trimmedInputValue,
                })
              }
            }}
          />
          <IconButton
            aria-label="Ask AI button"
            icon={<MdSend />}
            size="lg"
            backgroundColor="primary.500"
            _hover={{ backgroundColor: 'primary.600' }}
            color="white"
            colorScheme="primary.500"
            onClick={(e) => {
              e.stopPropagation()
              handleSubmit(inputValue)
              analytics.event('askAi_submitButton', {
                question: inputValue,
              })
            }}
            isDisabled={!trimmedInputValue}
            boxShadow="extended"
            stroke="#000"
            p="0"
          />
        </HStack>
      </Box>
      <Flex justifyContent="space-between">
        <Box mt={2} w="100%">
          <HStack
            onClick={hasPostedState ? toggleCollapse : noop}
            justifyContent={hasPostedState ? 'space-between' : 'end'}
          >
            {hasPostedState && (
              <Text
                fontSize="xs"
                fontWeight="medium"
                display="flex"
                cursor={'pointer'}
              >
                <Trans>Predefined questions</Trans>
                {hasPostedState && (
                  <Icon
                    ml={1}
                    as={collapsed ? MdKeyboardArrowUp : MdKeyboardArrowDown}
                    boxSize={4}
                  />
                )}
              </Text>
            )}
            <Text fontSize="xs" fontStyle="italic" color="gray.500">
              <Trans>
                Don’t include sensitive information in your question. Note, Ask
                AI can make mistakes
              </Trans>
            </Text>
          </HStack>
          <Collapse
            in={collapsed ?? !hasPostedState}
            animateOpacity
            transition={{ enter: { duration: 0.5 }, exit: { duration: 0.5 } }}
          >
            <Flex
              flexDir="column"
              flexWrap="wrap"
              w="100%"
              border={hasPostedState ? '1px solid' : 'none'}
              borderColor="gray.200"
              borderRadius="8px"
              py={3}
              px={hasPostedState ? 3 : 0}
            >
              {personalQuestions.length > 0 && (
                <>
                  <Flex flexDir="row" flexWrap="wrap" gap={2}>
                    <Box>
                      <Icon as={MdBookmarks} boxSize={3} />
                    </Box>
                    <Text
                      fontSize="xs"
                      fontWeight="medium"
                      display="flex"
                      cursor="pointer"
                    >
                      <Trans>Personal questions</Trans>
                    </Text>
                    <PersonalQuestions
                      questions={personalQuestions}
                      handleDeleteQuestion={deleteQuestion}
                      setState={setInputValue}
                      handleSubmit={handleSubmit}
                    />
                  </Flex>
                  <Text
                    fontSize="xs"
                    fontStyle="italic"
                    color="gray.500"
                    mt={1}
                    mb={6}
                  >
                    <Trans>
                      Personal questions are stored in this browser's local
                      storage, meaning they are exclusive to this device. NB! Be
                      aware that clearing your browser data can also erase these
                      questions.
                    </Trans>
                  </Text>
                </>
              )}
              <Text fontSize="xs" fontWeight="medium" display="flex" mb={2}>
                <Trans>Predefined questions</Trans>
              </Text>
              <Flex flexDir="row" flexWrap="wrap" w="100%" gap={2}>
                {aiQuestions?.map((question) => (
                  <Button
                    key={question.uid}
                    onClick={() => {
                      setInputValue(question.question)
                      handleSubmit(question.question)
                      analytics.event('askAi_predefinedQuestion_submit', {
                        predefinedQuestion: question.question,
                      })
                    }}
                    size="xs"
                    leftIcon={<MdSearch />}
                  >
                    {question.name}
                  </Button>
                ))}
              </Flex>
            </Flex>
          </Collapse>
        </Box>
      </Flex>
    </>
  )
}

export default QuestionInput
